// 订单详情页
<template>
	<div class="sld_order_list sld_order_info">
		<!-- <MemberTitle :memberTitle="L['订单详情']"></MemberTitle> -->
		<div class="container">
			<h3>{{ L["订单详情"] }}</h3>

			<div class="outer7 flex-col" v-if="detailInfo.data.orderSn">
				<div class="outer8 flex-col">
					<div class="section1 flex-col">
						<!-- <div class="box2 flex-col">
              <span class="word35">{{detailInfo.data.deliverTypeValue}}</span>
            </div> -->
						<div class="box1 flex-col">
							<div class="bd1 flex-col" style="padding-left:20px;">
								<div class="section2 flex-row">
									<span class="word25">订单号：{{ detailInfo.data.orderSn }}</span>
									<span class="word25 word26" v-if="detailInfo.data.purchaseSn">采购单号：{{
		detailInfo.data.purchaseSn
}}</span>
								</div>
							</div>
							<div class="bd2 flex-row">
								<template
									v-if="detailInfo.data.orderState == 0 || detailInfo.data.orderState == 21 || detailInfo.data.orderState == 60">
									<div class="img1_box active">
										<img class="img1" referrerpolicy="no-referrer" :src="pic_confirm" />
										<p>提交订单</p>
										<p v-if="detailInfo.data.orderLogList.length > 0">
											{{ detailInfo.data.orderLogList[0].logTime }}</p>
									</div>
									<div class="bd3 flex-col"></div>
									<div class="img1_box active">
										<img class="img1" referrerpolicy="no-referrer" :src="pic_finish" />
										<p v-if="detailInfo.data.orderState == 0">交易取消</p>
										<p v-else-if="detailInfo.data.orderState == 21">订单结束</p>
										<p v-else>交易关闭</p>
										<p v-if="detailInfo.data.orderLogList.length > 1">
											{{
		detailInfo.data.orderLogList[detailInfo.data.orderLogList.length
			-
			1].logTime
}}
										</p>
									</div>
								</template>
								<template v-else>
									<div class="img1_box" :class="{ active: detailInfo.data.orderState > 0 }">
										<img class="img1" referrerpolicy="no-referrer" :src="pic_confirm" />
										<p>提交订单</p>
										<p v-if="detailInfo.data.orderLogList.length > 0">
											{{ detailInfo.data.orderLogList[0].logTime }}</p>
									</div>
									<div v-if="detailInfo.data.expectDeliverTime || detailInfo.data.orderState == 11"
										class="bd3 flex-col">
									</div>
									<div v-if="detailInfo.data.expectDeliverTime || detailInfo.data.orderState == 11"
										class="img1_box"
										:class="{ active: detailInfo.data.orderState >= 20 }">
										<img v-if="detailInfo.data.orderState < 20" class="img1"
											referrerpolicy="no-referrer" :src="pic_uncheck" />
										<img v-else class="img1" referrerpolicy="no-referrer"
											:src="pic_check" />
										<p>供应商审核</p>
										<p
											v-if="detailInfo.data.orderLogList.length > 1 && detailInfo.data.orderState >= 20">
											{{ detailInfo.data.orderLogList[1].logTime }}</p>
									</div>
									<div class="flex-col"
										:class="detailInfo.data.orderState >= 20 ? 'bd3' : 'bd4'">
									</div>
									<div class="img1_box"
										:class="{ active: detailInfo.data.orderState >= 30 }">
										<img v-if="detailInfo.data.orderState < 30" class="img1"
											referrerpolicy="no-referrer" :src="pic_unpay" />
										<img v-else class="img1" referrerpolicy="no-referrer"
											:src="pic_pay" />
										<p>采购付款</p>
										<p
											v-if="detailInfo.data.expectDeliverTime && detailInfo.data.orderLogList.length > 2 && detailInfo.data.orderState >= 30">
											{{ detailInfo.data.orderLogList[2].logTime }}
										</p>
										<p
											v-if="!detailInfo.data.expectDeliverTime && detailInfo.data.orderLogList.length > 1 && detailInfo.data.orderState >= 30">
											{{ detailInfo.data.orderLogList[1].logTime }}
										</p>
									</div>
									<div class="flex-col"
										:class="detailInfo.data.orderState >= 30 ? 'bd3' : 'bd4'">
									</div>
									<div class="img1_box"
										:class="{ active: detailInfo.data.orderState >= 41 }">
										<img v-if="detailInfo.data.orderState < 41" class="img1"
											referrerpolicy="no-referrer" :src="pic_unsend" />
										<img v-else class="img1" referrerpolicy="no-referrer"
											:src="pic_send" />
										<p>供应商发货</p>
										<p
											v-if="detailInfo.data.expectDeliverTime && detailInfo.data.orderLogList.length > 3 && detailInfo.data.orderState >= 41">
											{{ detailInfo.data.orderLogList[3].logTime }}
										</p>
										<p
											v-else-if="!detailInfo.data.expectDeliverTime && detailInfo.data.orderLogList.length > 2 && detailInfo.data.orderState >= 41">
											{{ detailInfo.data.orderLogList[2].logTime }}
										</p>
									</div>
									<div class="flex-col"
										:class="detailInfo.data.orderState >= 41 ? 'bd3' : 'bd4'">
									</div>
									<div class="img1_box"
										:class="{ active: detailInfo.data.orderState >= 50 }">
										<img v-if="detailInfo.data.orderState < 50" class="img1"
											referrerpolicy="no-referrer" :src="pic_unfinish" />
										<img v-else class="img1" referrerpolicy="no-referrer"
											:src="pic_finish" />
										<p>交易完成</p>
										<p
											v-if="detailInfo.data.expectDeliverTime && detailInfo.data.orderLogList.length > 4 && detailInfo.data.orderState >= 50">
											{{ detailInfo.data.orderLogList[4].logTime }}
										</p>
										<p
											v-else-if="!detailInfo.data.expectDeliverTime && detailInfo.data.orderLogList.length > 3 && detailInfo.data.orderState >= 50">
											{{ detailInfo.data.orderLogList[3].logTime }}
										</p>
									</div>
								</template>
							</div>
							<div class="bd8 flex-col"></div>
							<div class="bd9 flex-row">
								<span class="word30">供应商名称：{{ detailInfo.data.storeName }}</span>
								<img class="label5" referrerpolicy="no-referrer" :src="storeChat"
									@click="chatInfo(detailInfo.data.storeId)" />
							</div>
							<div class="bd10 flex-row" v-if="detailInfo.data.deliverType==1">
								<div class="group26 flex-col">
									<span class="word32">收货人：{{ detailInfo.data.receiverName }}</span>
									<span class="word32 word33">收货地址：{{ detailInfo.data.receiverAreaInfo }}
										{{ detailInfo.data.receiverAddress }}</span>
								</div>
							</div>
							<span class="txt17" v-if="detailInfo.data.deliverType==1">手机号码：{{ detailInfo.data.receiverMobile }}</span>
							<span class="txt17">
								支付方式：{{ detailInfo.data.orderState == 20 && detailInfo.data.paymentCode !=
		'BANK' ?
		'--' : detailInfo.data.paymentName
}}
								<!-- <span class="txt17_pay"
									v-if="detailInfo.data.billInfo && detailInfo.data.billInfo.payId">查看账单>></span>
								<div class="mod18 flex-col" v-if="detailInfo.data.billInfo">
									<div class="group6 flex-col">
										<span class="info20 info20_tit">信用账单信息</span>
										<span class="info20 txt20" v-if="detailInfo.data.billInfo.state == 2">
											信用账单号：{{ detailInfo.data.billInfo.billPaySn }}
										</span>
										<span class="info20 txt20" v-if="detailInfo.data.billInfo.state == 3">
											结算单号：{{ detailInfo.data.billInfo.billPaySn }}
										</span>
										<span class="info20 info21">结算金额：{{
		detailInfo.data.billInfo.totalAmount
}}</span>
										<span class="info20 info21"
											v-if="detailInfo.data.billInfo.state != 4">
											结算状态：{{ detailInfo.data.billInfo.stateValue }}
										</span>
										<span class="info20 info21" v-else>结算状态：<span
												style="color:#f30213;font-weight:700;">已逾期</span></span>
										<span class="info20 info22"
											v-if="detailInfo.data.billInfo.state == 2 || detailInfo.data.billInfo.state == 3">
											结算方式：{{ detailInfo.data.billInfo.billMethod }}
										</span>
										<span class="info20 txt21">最后结算日：{{
		detailInfo.data.billInfo.overdueTime
			?
			detailInfo.data.billInfo.overdueTime : '--'
}}</span>
										<span class="info20 word33"
											v-if="detailInfo.data.billInfo.state == 2 || detailInfo.data.billInfo.state == 3">
											汇款时间：{{ detailInfo.data.billInfo.payTime }}
										</span>
										<span class="info20 word34"
											v-if="detailInfo.data.billInfo.state == 3">
											确认时间：{{ detailInfo.data.billInfo.auditTime }}
										</span>
										<div class="box55 flex-row"
											v-if="detailInfo.data.billInfo.state == 2 || detailInfo.data.billInfo.state == 3">
											<span class="word35"> 汇款凭证：</span>
											<div class="outer3 flex-col"
												v-if="detailInfo.data.billInfo.payVoucher">
												<img :src="detailInfo.data.billInfo.payVoucher"
													class="bd2 flex-col" />
											</div>
										</div>
									</div>
								</div> -->
							</span>
							<!-- <span class="txt117" v-if="detailInfo.data.paymentVoucher">汇款凭证：
								<el-image :src="detailInfo.data.paymentVoucher"
									:preview-src-list="[detailInfo.data.paymentVoucher]"
									style="width:140px;height:80px;" class="txt117_pic"></el-image>
							</span> -->
							<span class="txt17">配送方式：{{ detailInfo.data.deliverTypeValue ?
		detailInfo.data.deliverTypeValue
		: '--'
}}</span>
							<!-- <span class="txt17">期望交货时间：{{ detailInfo.data.expectDeliverTime ?
		detailInfo.data.expectDeliverTime : '--'
}}</span> -->
							<span class="txt17">备注：{{ detailInfo.data.orderRemark ? detailInfo.data.orderRemark :
		'--'
}}</span>
							<span class="txt17">供应商备注：{{detailInfo.data.auditRemark?detailInfo.data.auditRemark:'--'}}</span>
						</div>
						<div class="outer10 flex-col"></div>
						<div class="box5">
							<!-- detailInfo.data.orderState: 
                  0-已取消；10-提交订单待审核；20-审核通过待支付；
                  21-审核拒绝订单结束；30-待发货；40-部分发货待收货；
                  41-全部发货待收货；50-已完成；60-已关闭 
                -->
							<!-- 待审核 -->
							<span class="word31" v-if="detailInfo.data.orderState == 10">等待供应商审核</span>
							<!-- 待付款 -->
							<span class="word31" v-if="detailInfo.data.orderState == 11">等待供应商报价</span>

							<span class="word31"
								v-else-if="detailInfo.data.orderState == 20 && detailInfo.data.paymentCode != 'BANK'">供应商审核通过，等待采购付款</span>
							<span class="word31"
								v-else-if="detailInfo.data.orderState == 20 && detailInfo.data.paymentCode == 'BANK'">{{
		detailInfo.data.paymentName + '待确认'
}}</span>
							<!-- 待发货 -->
							<span class="word31" v-else-if="detailInfo.data.orderState == 30">等待供应商发货</span>
							<!-- 分批发货 -->
							<span class="word31" v-else-if="detailInfo.data.orderState == 40">供应商已分批发货</span>
							<!-- 确认收货 -->
							<span class="word31" v-else-if="detailInfo.data.orderState == 41">等待买家确认收货</span>
							<!-- 关闭 -->
							<span class="word31" v-else-if="detailInfo.data.orderState == 60">交易关闭</span>
							<!-- 审核拒绝 -->
							<span class="word31" v-else-if="detailInfo.data.orderState == 21">供应商审核拒绝，订单结束</span>
							<!-- 审核拒绝 -->
							<span class="word31" v-else-if="detailInfo.data.orderState == '0'">订单已取消</span>
							<!-- 交易完成 -->
							<span class="word31" v-else-if="detailInfo.data.orderState == 50">完成交易</span>
							<!-- <div class="remainTime" v-if="detailInfo.data.orderState == 20 || detailInfo.data.orderState == 4">剩余23小时59分钟自动关闭</div> -->
							<div class="reason" v-if="detailInfo.data.orderState == '0'">
								取消原因：{{ detailInfo.data.cancelReason || '--' }}
							</div>
							<div class="remark" v-if="detailInfo.data.orderState == '0'">
								备注：{{ detailInfo.data.cancelRemark || '--' }}
							</div>
							<div class="reason" v-else-if="detailInfo.data.orderState == 21">
								拒绝理由：{{ detailInfo.data.refuseReason }}
							</div>
							<template v-if="opStateBtn == 'ope'">
								<button class="group27 flex-col" @click="pay(detailInfo.data.paySn)"
									v-if="detailInfo.data.orderState == 20">
									<span class="txt15">{{ L['付款'] }}</span>
								</button>
								<button class="group27 flex-col" @click="cancle"
									v-if="detailInfo.data.orderState == 10 ||
	(detailInfo.data.orderState == 20 && detailInfo.data.paymentCode != 'BANK') || detailInfo.data.orderState == 11">
									<span class="txt15">{{ L['取消订单'] }}</span>
								</button>
								<!-- <button class="group28 flex-col" @click="editAddress"
									v-if="detailInfo.data.orderState == 10 || detailInfo.data.orderState == 20 || detailInfo.data.orderState == 30 || detailInfo.data.orderState == 11">
									<span class="txt15">{{ L['修改地址'] }}</span>
								</button> -->
								<button class="group28 flex-col" @click="confirmReceipt"
									v-if="detailInfo.data.orderState == 41 && detailInfo.data.lockState == 0">
									<span class="txt15">{{ L['确认收货'] }}</span>
								</button>
								<!-- <button class="group28 flex-col" @click="remainEvaluated"
									v-if="detailInfo.data.orderState == 50 && detailInfo.data.evaluateState != 3">
									<span class="txt15">评价</span>
								</button> -->
								<!-- <button class="group28 flex-col" @click="delOrder"
									v-if="detailInfo.data.orderState == 0 || detailInfo.data.orderState == 50 || (detailInfo.data.orderState == 40 && detailInfo.data.evaluateState == 3) && !permission">
									<span class="txt15">{{ L['删除订单'] }}</span>
								</button> -->
							</template>
						</div>
					</div>

					<!-- 物流信息 start -->
					<template v-if="(detailInfo.data.orderDeliver || detailInfo.data.orderDeliverList)&&false">
						<span class="txt21">物流信息</span>
						<!-- 齐套发货 -->
						<template v-if="detailInfo.data.orderDeliver">
							<div class="sld_deliver_box">
								<div class="logistics_info">
									<p>
										{{ logistics_info.data.type == 1 ? L['联系电话'] : L['承运人'] }}：{{
		logistics_info.data.type == 1 ?
			detailInfo.data.orderDeliver.deliverMobile :
			logistics_info.data.expressName
}}
									</p>
									<p>
										{{ logistics_info.data.type == 1 ? L['联系人'] : L['运单号'] }}：{{
		logistics_info.data.type == 1 ?
			detailInfo.data.orderDeliver.deliverName :
			logistics_info.data.expressNumber
}}
									</p>
								</div>
								<div class="logistics_list_con"
									v-if="logistics_info.data.routeList && logistics_info.data.routeList.length > 0">
									<el-timeline>
										<el-timeline-item
											v-for="(logistics, index) in logistics_info.data.routeList"
											:key="index"
											:timestamp="logistics.acceptStation ? logistics.acceptStation : logistics.remark">
											{{ logistics.acceptTime }}</el-timeline-item>
									</el-timeline>
								</div>
								<SldCommonEmpty v-else :tip="logistics_info.data.reason" totalWidth="934px" />
							</div>
						</template>
						<!-- 分批发货 -->
						<template v-else-if="detailInfo.data.orderDeliverList">
							<div class="sld_order_nav">
								<div class="sld_order_nav_con flex_row_start_center">
									<div class="item" :class="{ active: deliverIndex == index }" :key="item"
										@click="changeDeliver(index)"
										v-for="(item, index) in detailInfo.data.orderDeliverList">批次{{ index +
		1
}}
									</div>
									<div v-if="remainProduct.data.length > 0" class="item" @click="showRemain"
										:class="{ active: deliverIndex == detailInfo.data.orderDeliverList.length }">
										待发货</div>
								</div>
							</div>
							<div class="sld_deliver_box">
								<div class="section3">
									<span class="word1">产品信息</span>
									<span class="word2">单价</span>
									<span class="word3">数量</span>
									<span class="word4">总价</span>
								</div>
								<!-- 已发货数据 -->
								<template v-if="deliverIndex < detailInfo.data.orderDeliverList.length">
									<template
										v-for="item in detailInfo.data.orderDeliverList[deliverIndex].productInfoList"
										:key="item">
										<div class="section4 flex-col">
											<div class="mod6 flex-row">
												<div class="mod7 flex-col" @click="detail(item.productId)">
													<img class="section5 flex-col"
														:src="item.goodsImage" />
												</div>
												<div class="mod8 flex-col">
													<span class="infoBox1 overflow_ellipsis_two"
														@click="detail(item.productId)">
														{{ item.goodsName }}
													</span>
													<span class="word42 overflow_ellipsis_two">
														{{ item.specValues }}
													</span>

													<span class="word42 overflow_ellipsis_two"
														v-if="item.orderCode">
														订货编码：{{ item.orderCode }}
													</span>
												</div>

												<span class="word5 flex_row_center_center">
													<span v-if="item.goodsPrice">￥{{
		item.goodsPrice.toFixed(2)
}}</span>
													<div class=" type2" v-else></div>
												</span>
												<span class="word6">{{ item.deliverNum }}</span>
												<span class="word7">￥{{ item.totalAmount.toFixed(2)
}}</span>
											</div>
										</div>
									</template>
									<template v-if="logistics_info.data.type">
										<div class="logistics_info">
											<p>
												<span>{{ logistics_info.data.type == 1 ? L['联系电话'] :
		L['承运人']
}}：</span>
												<span
													v-if="logistics_info.data.type == 1 && detailInfo.data.orderDeliver">
													{{ detailInfo.data.orderDeliver.deliverMobile }}
												</span>
												<span v-else>{{ logistics_info.data.expressName }}</span>
											</p>
											<p>
												<span>{{ logistics_info.data.type == 1 ? L['联系人'] : L['运单号']
}}：</span>
												<span
													v-if="logistics_info.data.type == 1 && detailInfo.data.orderDeliver">{{
		detailInfo.data.orderDeliver.deliverName
}}</span>
												<span v-else> {{ logistics_info.data.expressNumber }}</span>
											</p>
										</div>
										<div class="logistics_list_con"
											v-if="logistics_info.data.routeList && logistics_info.data.routeList.length > 0">
											<el-timeline>
												<el-timeline-item
													v-for="(logistics, index) in logistics_info.data.routeList"
													:key="index"
													:timestamp="logistics.acceptStation ? logistics.acceptStation : logistics.remark">
													{{ logistics.acceptTime }}</el-timeline-item>
											</el-timeline>
										</div>
										<SldCommonEmpty v-else :tip="logistics_info.data.reason"
											totalWidth="934px" />
									</template>
								</template>
								<!-- 待发货数据 -->
								<template v-else>
									<template v-for="item in remainProduct.data" :key="item">
										<div class="section4 flex-col">
											<div class="mod6 flex-row">
												<div class="mod7 flex-col" @click="detail(item.productId)">
													<img class="section5 flex-col"
														:src="item.goodsImage" />
												</div>
												<div class="mod8 flex-col">
													<span class="infoBox1 overflow_ellipsis_two"
														@click="detail(item.productId)"
														:title="(item.goodsSpec && item.goodsSpec.length > 56) ? item.goodsSpec : ''">
														{{ item.goodsName }}
													</span>
													<span class="word42 overflow_ellipsis_two">
														{{ item.specValues }}
													</span>

													<span class="word42 overflow_ellipsis_two"
														v-if="item.orderCode">
														订货编码：{{ item.orderCode }}
													</span>
												</div>

												<span class="word5">￥{{ item.goodsPrice.toFixed(2) }}</span>
												<span class="word6">{{ item.deliverNum }}</span>
												<span class="word7">￥{{ item.moneyAmount.toFixed(2)
}}</span>
											</div>
										</div>
									</template>
								</template>
							</div>
						</template>
					</template>
					<!-- 物流信息 end -->

					<!-- 产品信息 start -->
					<span class="txt21">产品信息</span>
					<div class="mod5_box">
						<div class="section3 flex-col">
							<div class="mod5 flex-row">
								<!-- <span class="word36">序号</span> -->
								<span class="word36 info9">产品信息</span>
								<span class="word36 word37">规格</span>
								<span class="word36 word38">材质</span>
								<span class="word36 word39">产地</span>
								<span class="word36 word41">货物存在地</span>
								<span class="word36 word44">单价（元/吨）</span>
								<!-- <span class="word36 word40">运费</span> -->
								<span class="word36 word42">总价</span>
							</div>
						</div>
						<template v-for="(item, index) in detailInfo.data.orderProductList" :key="item">
							<div class="section4 flex-col">
								<div class="mod6 flex-row">
									<!-- <span class="word41">
										<img src="@/assets/ze-add.png" class="word41_12" v-if="!item.check" alt="" @click="gasseto(index)">
										<span class="word41_12 word41_13" v-else  @click="gasseto(index)">
											<span></span>
										</span>
										 {{ index + 1 }}
									</span> -->
									<div class="mod7 flex-col" @click="detail(item.productId)">
										<img class="section5 flex-col" :src="item.goodsImage" />
									</div>
									<div class="mod8 flex-col">
										<span class="infoBox1 overflow_ellipsis_two"
											@click="detail(item.productId)"
											:title="(item.goodsSpec && item.goodsSpec.length > 50) ? item.goodsSpec : ''">
											{{ item.goodsName }}
										</span>
									</div>
									<div class="mod10 flex-col">
										<span class="info12">{{ item.specModel ?
		item.specModel : '--'
}}</span>
									</div>
									<span class="word45">{{ item.material?item.material:'--' }}</span>
									<span class="word45">{{ item.originPlace?item.originPlace:'--' }}</span>
									<span class="word45">{{ item.presencePlace?item.presencePlace:'--' }}</span>
									<span class="word45">￥{{ item.goodsPrice?item.goodsPrice.toFixed(2):'--'}}/吨</span>
									<!-- <span class="word45">{{ 
											`￥${$getPartNumber(detailInfo.data.expressSinglePrice, 'all')+'/吨'}`
									}}</span> -->
									<span class="word45">￥{{ item.moneyAmount?(Number(item.moneyAmount)+item.goodsDeliverPriceSum).toFixed(2):'--'}}</span>
								</div>
								<div class="section4_child flex-row" >
									<div class="flex-row">
										<p>销售单位：</p>
										<span v-if="item.type==1">米</span>
										<span v-if="item.type==2">片</span>
										<span v-if="item.type==3">根</span>
										<span v-if="item.type==4">吨</span>
										<span v-if="item.type==5">卷</span>
									</div>
									<div class="flex-row" v-if="item.type==1">
										<p>长度：</p>
										<span>{{item.buyNumType}}米={{item.sumKG}}吨</span>
									</div>
									<div class="flex-row" v-if="item.type==2">
										<p>片数：</p>
										<span>{{item.buyNumType}}片={{item.sumKG}}吨</span>
									</div>
									<div class="flex-row" v-if="item.type==3">
										<p>根数：</p>
										<span>{{item.buyNumType}}根={{item.sumKG}}吨</span>
									</div>
									<div class="flex-row" v-if="item.type==4">
										<p>吨数：</p>
										<span>{{item.buyNumType}}吨={{item.sumKG}}吨</span>
									</div>
									<div class="flex-row" v-if="item.type==5">
										<p>卷重：</p>
										<span>{{item.rollWight}}吨</span>
									</div>
									<div class="flex-row" >
										<p>交货方式：</p>
										<span>{{ item.deliverType==1?'净板交货':'带头尾交货' }}</span>
									</div>
								</div>
							</div>
						</template>
					</div>
					<!-- 产品信息 end -->

					<!-- 价格栏位 -->
					<div class="store_item_bottom_money">
						<p class="store_item_bottom_money_p">合计总吨数：<span>￥{{detailInfo.data.sumKG}}吨</span>
						</p>
						<p class="store_item_bottom_money_p">产品金额：<span>￥{{
		$getPartNumber(detailInfo.data.goodsAmount, 'all')
}}</span>
						</p>
						<p class="store_item_bottom_money_p" v-if="detailInfo.data.deliverType==1 || detailInfo.data.deliverType==2">
							运费单价：<span>{{ detailInfo.data.expressSinglePrice ?
		`￥${$getPartNumber(detailInfo.data.expressSinglePrice, 'all')+'/吨'}` :
		'免运费'
}}</span>
						</p>

						<p class="store_item_bottom_money_p" v-if="detailInfo.data.deliverType==1 || detailInfo.data.deliverType==2">
							运费：<span>{{ detailInfo.data.expressFee ?
		`￥${$getPartNumber(detailInfo.data.expressFee, 'all')}` :
		'免运费'
}}</span>
						</p>
                        <p class="store_item_bottom_money_p" v-if="(detailInfo.data.deliverType==1 || detailInfo.data.deliverType==2)&&detailInfo.data.activityDiscountAmount">
							优惠：<span>{{ 
		`￥${$getPartNumber(detailInfo.data.activityDiscountAmount, 'all')}`
}}</span>
						</p>

						<p  v-if="detailInfo.data.deliverType==1 || detailInfo.data.deliverType==2">小计：<span>￥{{ $getPartNumber(detailInfo.data.orderAmount, 'all') }}</span></p>
					</div>
					<!-- 价格栏位 -->

				</div>
			</div>
		</div>
		<!-- 取消理由弹窗 start -->
		<el-dialog :title="L['取消订单理由']" v-model="cancel_order" customClass="select_reason_width"
			:before-close="current_reason_id = ''" lock-sroll="false">
			<div class="cancel_list_con">
				<div v-for="(reasonItem, index) in cancel_list.data" :key="index"
					:class="{ reason_item: true, flex_row_between_center: true, active: current_reason_id == reasonItem.reasonId }"
					@click="selectReason(reasonItem.reasonId)">
					<img :src="current_reason_id == reasonItem.reasonId ? selected : not_select" alt />
					<span class="reason_text">{{ reasonItem.content }}</span>
				</div>
			</div>
			<div class="confirm_cancel_btn" @click="confirmCancel()">{{ L['确定'] }}</div>
		</el-dialog>
		<!--  取消地址理由弹窗 end  -->

		<!-- 地址选择弹窗 start -->
		<el-dialog title="修改地址" v-model="show_select_address" customClass="select_address_width" lock-sroll="false">
			<div class="out_stock_dialog address_con">
				<div v-for="(item, index) in address_list.data" :key="index" @click="selectAddress(index)"
					:class="{ address_item: true, flex_column_start_start: true, select: current_address_index == index }">
					<span>{{ L['收货人'] }}：{{ item.memberName }}</span>
					<span>{{ L['联系方式'] }}：{{ item.telMobile }}</span>
					<div class="address_text" :title="item.addressAll + item.detailAddress">
						<span>{{ L['收货地址'] }}：</span>
						<span class="overflow_ellipsis_clamp2">{{ item.addressAll }} {{ item.detailAddress
}}</span>
					</div>
					<img v-if="current_address_index == index" class="selected" :src="invoice_selected" alt />
				</div>
			</div>
			<div class="btn_con flex_row_center_center">
				<div class="remove red" @click="confirmChangeAddress()">{{ L['提交修改'] }}</div>
			</div>
		</el-dialog>
		<!-- 地址选择弹窗 end -->

	</div>
</template>

<script>
import { ref, reactive, getCurrentInstance, onMounted } from "vue";
import { useStore } from 'vuex';
import MemberTitle from "@/components/MemberTitle";
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from 'element-plus';
import SldCommonEmpty from '@/components/SldCommonEmpty';
export default {
	name: "order-info",
	components: {
		MemberTitle,
		SldCommonEmpty,
	},
	setup() {
		const router = useRouter();
		const route = useRoute();
		const store = useStore();
		const memberInfo = ref(store.state.memberInfo);
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const memberId = ref(route.query.memberId);
		const orderSn = ref(route.query.orderSn);
		const permission = ref(route.query.permission);
		const detailInfo = reactive({ data: {} });
		const pic_confirm = require('@/assets/order/order_confirm.png');
		const pic_uncheck = require('@/assets/order/order_uncheck.png');
		const pic_check = require('@/assets/order/order_check.png');
		const pic_unpay = require('@/assets/order/order_unpay.png');
		const pic_pay = require('@/assets/order/order_pay.png');
		const pic_unsend = require('@/assets/order/order_unsend.png');
		const pic_send = require('@/assets/order/order_send.png');
		const pic_unfinish = require('@/assets/order/order_unfinish.png');
		const pic_finish = require('@/assets/order/order_finish.png');
		const storeChat = require('@/assets/cart/storeChat.png');
		const deliverIndex = ref(0); //物流信息导航栏下标
		const deliverId = ref(null); //物流id
		const logistics_info = reactive({
			data: {
				type: '',
				expressName: '',
				expressNumber: '',
				routeList: [],
				reason: ' '
			}
		});
		const remainProduct = reactive({ data: [] }); //待发货数据
		const opStateBtn = ref('ope')
		/** 取消原因 */
		const cancel_order = ref(false); //是否显示取消订单弹窗
		const current_reason_id = ref(); //记录当前已选择理由id
		const cancel_list = reactive({ data: [] });
		const not_select = require("@/assets/order/not_select.png");
		const selected = require("@/assets/order/selected.png");
		/** 取消原因 */

		/** 地址选择 */
		const show_select_address = ref(false);
		const address_list = reactive({ data: [] });
		const current_address_index = ref(0);
		const invoice_selected = require("@/assets/buy/invoice_selected.png");
		const orderAddress = reactive({ data: "" });
		/** 地址选择 */

		const getDetail = () => { //订单详情
			let param = {
				orderSn: orderSn.value
			}
			if (memberId.value) {
				param.memberId = memberId.value
			}
			proxy
				.$get(`v3/business/front/orderInfo/detail`, param)
				.then((res) => {
					if (res.state == 200) {
						res.data.orderProductList.forEach(item => {
							item.check = false
						});
						detailInfo.data = res.data;
						if (detailInfo.data.orderState >= 30) {
							if (detailInfo.data.orderDeliver && detailInfo.data.orderDeliver.deliverType == 2) {
								logistics_info.data.type = 1;
								logistics_info.data.reason = '无需物流';
							} else {
								lookLogistics();
							}
						}
					} else {
						let msg = res.msg ? res.msg : '订单详情数据错误'
						ElMessage.error(msg);
						setTimeout(() => {
							router.back();
						}, 1000)
					}
				})
		}

		const pay = (paySn) => { //付款
			router.push({
				path: "/buy/pay",
				query: {
					paySn: paySn,
					payFrom: 2
				}
			});
		};

		// 获取订单取消理由列表
		const getCancelList = () => {
			proxy
				.$get("v3/system/front/reason/list", {
					type: 104
				})
				.then(res => {
					if (res.state == 200) {
						cancel_list.data = res.data;
					} else {
						ElMessage(res.msg);
					}
				})
		};
		const cancle = () => {
			cancel_order.value = true;
		};
		//选择理由
		const selectReason = id => {
			current_reason_id.value = id;
		};
		//确认取消订单
		const confirmCancel = () => {
			if (!current_reason_id.value) {
				ElMessage.warning(L["请选择取消理由"]);
				return;
			}
			proxy
				.$post("v3/business/front/orderOperate/cancel", {
					orderSn: orderSn.value,
					reasonId: current_reason_id.value
				})
				.then(res => {
					if (res.state == 200) {
						ElMessage.success(L["取消订单成功"]);
						cancel_order.value = false;
						getDetail();
					} else {
						ElMessage(res.msg);
					}
				})
				.catch(() => {
					//异常处理
				});
		};

		const editAddress = () => {
			getAddressList();
			show_select_address.value = true;
		};
		//获取地址列表
		const getAddressList = () => {
			proxy
				.$get("v3/member/front/memberAddress/list")
				.then(res => {
					if (res.state == 200) {
						address_list.data = res.data.list;
					} else {
						ElMessage(res.msg);
					}
				})
				.catch(() => {
					//异常处理
				});
		};
		//切换地址
		const selectAddress = index => {
			current_address_index.value = index;
		};
		//确认修改地址
		const confirmChangeAddress = () => {
			orderAddress.data = address_list.data[current_address_index.value];
			show_select_address.value = false;
			proxy
				.$post("v3/business/front/orderOperate/updateAddress", {
					orderSn: orderSn.value,
					addressId: orderAddress.data.addressId
				})
				.then(res => {
					if (res.state == 200) {
						ElMessage.success(res.msg);
						getDetail();
					} else {
						ElMessage.error(res.msg);
					}
				})
		};

		const confirmReceipt = () => { //提交订单
			let param = {
				orderSn: orderSn.value
			}
			ElMessageBox.confirm(L["确认收货?"], L["提示"], {
				confirmButtonText: L["确定"],
				cancelButtonText: L["取消"],
				type: "warning"
			})
				.then(() => {
					proxy
						.$post("v3/business/front/orderOperate/receive", param)
						.then(res => {
							if (res.state == 200) {
								ElMessage.success(L["确认收货成功"]);
								getDetail();
							} else {
								ElMessage.error(res.msg);
							}
						})
				})
		};

		//删除订单
		const delOrder = () => {
			ElMessageBox.confirm(L["确认删除该订单?"], L["提示"], {
				confirmButtonText: L["确定"],
				cancelButtonText: L["取消"],
				type: "warning"
			})
				.then(() => {
					let param = {
						orderSn: orderSn.value
					}
					proxy
						.$post("v3/business/front/orderOperate/delete", param)
						.then(res => {
							if (res.state == 200) {
								ElMessage.success(L["删除订单成功"]);
								setTimeout(() => {
									router.back();
								}, 500)
							} else {
								ElMessage.error(res.msg);
							}
						})
						.catch(() => {
							//异常处理
						});
				})
				.catch(() => { });
		};

		const lookLogistics = () => { //查看物流
			let param = {}
			if (detailInfo.data.orderDeliver) {
				param.deliverId = detailInfo.data.orderDeliver.deliverId
			} else if (detailInfo.data.orderDeliverList && detailInfo.data.orderDeliverList.length > 0) {
				param.deliverId = detailInfo.data.orderDeliverList[deliverIndex.value].deliverId
			} else {
				return;
			}
			// 获取已发货数据
			proxy
				.$get("v3/business/front/logistics/order/getTrace", param)
				.then(res => {
					if (res.state == 200) {
						if (!res.data.reason) {
							if (detailInfo.data.orderDeliverList && detailInfo.data.orderDeliverList.length > 0) {
								res.data.reason = detailInfo.data.orderDeliverList[deliverIndex.value].deliverType == 2 ? '无需物流' : '暂无详细物流信息';
							} else {
								res.data.reason = '暂无详细物流信息';
							}
						}
						logistics_info.data = res.data;
					} else {
						ElMessage(res.msg);
					}
				})
			if (detailInfo.data.orderDeliverList && detailInfo.data.orderDeliverList.length > 0) {
				let params = {
					orderSn: orderSn.value
				}
				// 获取待发货数据
				proxy
					.$get("v3/business/front/orderInfo/orderProductList", params)
					.then(res => {
						if (res.state == 200) {
							remainProduct.data = res.data;
						}
					})
			}
		};

		const changeDeliver = (index) => {
			deliverIndex.value = index;
			logistics_info.routeList = [];
			lookLogistics();
		};

		const showRemain = () => {
			deliverIndex.value = detailInfo.data.orderDeliverList.length;
		};

		const detail = (id) => { //查看商品详情
			router.push({
				path: `/goods/detail`,
				query: {
					productId: id
				}
			})
		}

		const invoiceInfo = (id) => { //查看发票
			router.push({
				path: `/member/invoice/info`,
				query: {
					id: id
				}
			})
		};

		const contractInfo = (id) => { //查看合同
			router.push({
				path: `/member/contract/detail`,
				query: {
					id: id
				}
			})
		};

		const refund = (id) => { //退款
			let param = {
				orderProductId: id,
				orderSn: detailInfo.data.orderSn
			}
			proxy
				.$get('v3/business/front/return/apply/applyInfo', param)
				.then(res => {
					if (res.state == 200) {
						router.push({
							path: "/member/order/refund/apply",
							query: {
								orderState: detailInfo.data.orderState,
								orderProductId: id,
								isBank: detailInfo.data.paymentCode == 'BANK' ? 'true' : ''
							}
						});
					} else {
						ElMessage.error(res.msg)
					}
				})
		};

		const refundDetail = (afsSn) => { //退款详情
			router.push({
				path: '/member/order/refund/detail',
				query: {
					orderState: detailInfo.data.orderState,
					afsSn: afsSn
				}
			})
		};

		const chatInfo = (id) => { //客服

			getStoreDetail(id, (item) => {
				let chatInfo = {
					storeId: item.storeId,
					vendorAvatar: item.storeLogo,
					storeName: item.storeName,
					source: `从个人中心进入 供应商:${item.storeName}`
				}
				store.commit('saveChatBaseInfo', chatInfo)

				let newWin = router.resolve({
					path: '/service',
					query: {
						vid: id
					}
				})
				window.open(newWin.href, '_blank');
			})
		};


		const getStoreDetail = (storeId, callback) => {
			proxy.$get('v3/seller/front/store/detail', { storeId }).then(res => {
				if (res.state == 200) {
					if (callback && typeof callback == "function") {
						callback(res.data)
					}
				}
			})
		}


		const remainEvaluated = () => {
			router.push({
				path: "/member/order/evaluate",
				query: {
					orderSn: detailInfo.data.orderSn
				}
			});
		}

		const gasseto = (index)=>{
			detailInfo.data.orderProductList[index].check = !detailInfo.data.orderProductList[index].check
		}

		onMounted(() => {
			getDetail();
			getCancelList();
			let { opState } = route.query
			if (opState && opState == 'nope') {
				opStateBtn.value = 'nope'
			}
		});

		return {
			L,
			remainEvaluated,
			memberInfo,
			pic_confirm,
			pic_uncheck,
			pic_check,
			pic_unpay,
			pic_pay,
			pic_unsend,
			pic_send,
			pic_unfinish,
			pic_finish,
			storeChat,
			chatInfo,
			orderSn,
			detailInfo,
			pay,
			cancle,
			editAddress,
			getAddressList,
			selectAddress,
			confirmChangeAddress,
			detail,
			invoiceInfo,
			contractInfo,
			refund,
			refundDetail,
			getCancelList,
			confirmCancel,
			cancel_order,
			current_reason_id,
			cancel_list,
			selectReason,
			not_select,
			selected,
			show_select_address,
			address_list,
			current_address_index,
			invoice_selected,
			orderAddress,
			confirmReceipt,
			delOrder,
			lookLogistics,
			deliverIndex,
			deliverId,
			changeDeliver,
			logistics_info,
			remainProduct,
			showRemain,
			memberId,
			opStateBtn,
			permission,
			gasseto
		};
	},
};
</script>
<style lang="scss" scoped>
@import "../../../style/orderInfo.scss";
</style>
<style lang="scss">
.sld_order_info {
	.select_reason_width {
		width: 560px !important;

		::-webkit-scrollbar {
			width: 0 !important;
		}

		::-webkit-scrollbar {
			width: 0 !important;
			height: 0;
		}
	}

	.cancel_list_con,
	.logistics_list_con {
		width: 520px;
		margin: 0 auto;
		height: 300px;
		overflow-y: scroll;
		padding-top: 1px;

		.reason_item {
			width: 100%;
			height: 40px;
			margin-bottom: 15px;
			padding: 0 25px;
			justify-content: flex-start;
			cursor: default;

			img {
				width: 16px;
				height: 16px;
				margin-right: 14px;
			}

			&.active {
				background: #f8f8f8;
			}
		}
	}

	.confirm_cancel_btn {
		width: 80px;
		height: 30px;
		background: $colorMain;
		border-radius: 3px;
		line-height: 30px;
		text-align: center;
		color: white;
		margin: 0 auto;
		margin-top: 20px;
		cursor: pointer;
	}

	.select_address_width {
		width: 500px !important;
	}

	//商品无货缺货
	.out_stock_dialog {
		width: 460px;
		margin: 0 auto;
		height: 330px;
		overflow-y: scroll;

		.good_item {
			font-size: 14px;

			img {
				width: 80px;
				height: 80px;
			}

			.good_info {
				margin-left: 10px;
			}

			.good_name {
				width: 320px;
				color: #333333;
				line-height: 14px;
				margin-top: 10px;
				display: inline-block;
			}

			.spec_num {
				margin-top: 26px;

				.good_spec {
					color: #999999;
				}

				.good_num {
					float: right;
					color: #333333;
				}
			}
		}
	}

	.btn_con {
		font-size: 14px;
		margin-top: 20px;
		cursor: pointer;

		.return {
			cursor: pointer;
			width: 60px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			color: #333333;
			border-radius: 3px;
			border: 1px solid #dddddd;
		}

		.red {
			background-color: $colorMain;
			color: white;
		}

		.remove {
			width: 120px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			border-radius: 3px;
			background-color: $colorMain;
			margin-left: 10px;
			color: white;
		}
	}

	// 选择地址
	.address_con {
		height: 330px;
		overflow-y: scroll;
		scrollbar-width: none !important;
		-ms-overflow-style: none !important;

		.address_item {
			&:not(:first-child) {
				margin-top: 20px;
			}

			width: 458px;
			//    height: 104px;
			box-sizing: border-box;
			border: 1px solid #dfdfdf;
			position: relative;
			padding: 20px;

			span,
			div:not(:first-child) {
				margin-top: 12px;
			}

			.address_text {
				display: flex;
				width: 400px;
			}

			.selected {
				width: 14px;
				height: 14px;
				position: absolute;
				right: 0;
				bottom: 0;
			}
		}

		.select {
			border: 1px solid $colorMain;
		}
	}

	.overflow_ellipsis_clamp2 {
		width: 300px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		word-break: break-all;
		line-height: 19px;
	}

	.select_address_width {
		::-webkit-scrollbar {
			width: 0 !important;
		}

		::-webkit-scrollbar {
			width: 0 !important;
			height: 0;
		}
	}

	.store_item_bottom_money {
		border-top: 1px solid #d9d9d9;
		text-align: right;
		margin-left: 20px;
		margin-right: 20px;
		padding-top: 20px;
		padding-bottom: 20px;

		p {
			color: #686868;
			font-weight: normal;

			&.store_item_bottom_money_p {
				margin-bottom: 20px;
			}

			span {
				display: inline-block;
				min-width: 90px;
				color: $colorE;
				font-weight: 700;
			}
		}
	}
}
</style>